import CharltonHobbs from './ChartonHobbs.jpg'
import Google from './Google.jpg'
import Infusion from './Infusion.jpg'
import Labatt from './Labatt.jpg'
import Microsoft from './Microsoft.jpg'
import MintAgency from './MintAgency.jpg'
import MoetHennessy from './MoetHennessy.png'
import Nespresso from './Nespresso.jpg'
import RBC from './RBC.jpg'
import Rosewood from './Rosewood.jpg'
import SelectWines from './SelectWines.jpg'
import WayneGretzky from './WayneGretzky.jpg'

const ClientImages = {
  CharltonHobbs: CharltonHobbs,
  Google: Google,
  Infusion: Infusion,
  Labatt: Labatt,
  Microsoft: Microsoft,
  MintAgency: MintAgency,
  MoetHennessy: MoetHennessy,
  Nespresso: Nespresso,
  RBC: RBC,
  Rosewood: Rosewood,
  SelectWines: SelectWines,
  WayneGretzky: WayneGretzky
}

export default ClientImages
