import React from "react"
import './serviceLink.css'

function ServiceLink(props){
  return (
    <div className="service-link">
      <div className="service-link-top flex-stack">
        <div className="service-link-image flex1" style={{backgroundImage: `url(${props.image})`}}>
        </div>
        {props.title}
      </div>
      <div className="service-link-anchor">
        <a href={props.link}>Learn More</a>
      </div>
    </div>
  )
}

export default ServiceLink
