import React from "react"
import ClientImages from '../images/clients/clients'
import './ClientList.css'

function ClientList(){
  let images = ClientImages
  return (
    <div className="">
    <h2 className="client-title">
      <span>Our Clients</span>
      </h2>
    <div className="clients-wrapper flex">
      {Object.keys(images).map(key => {
        return <a href="/case-studies"><div key={key} className="client-image"
        style={{backgroundImage: `url(${images[key]})`}}></div></a>
      })}
    </div>
    </div>
  )
}

export default ClientList
