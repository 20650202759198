import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import logo from '../images/libation-white.png'
import hero2 from '../images/home-hero-2.jpg'
import wineGlass from '../images/icon-wine-glass.png'
import shotGlass from '../images/icon-shot-glass.png'
import martiniGlass from '../images/icon-martini-glass.png'
import tallGlass from '../images/icon-tall-glass.png'
import safetyImage from '../images/safety.png'
import './index.css'
import ServiceLink from '../components/serviceLink'
import {indexCopy} from '../copy/indexCopy'
import ClientList from '../components/ClientList'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`libation`, `liquour`, `ambassadorship`, `training`]} />
    <div id="index-page">
      <div className="hero flex">
        <img className="hero-logo" src={logo} alt="Logo" />
        <p>is a</p>
        <p>full service</p>
        <p>beverage</p>
        <p>consultation company</p>
      </div>
      <div className="page-section flex">
        <h1 className="brand-large">Libation</h1>
        <p>{indexCopy.intro1}</p>
        <p>{indexCopy.intro2}</p>
      </div>
      <div className="page-section hero2 flex" style={{backgroundImage: `url(${hero2})`}}>
        <div className="sercive-links-wrapper flex">
          <ServiceLink {...{title: "Staff & Consumer Training", link:"services/development-training", image: martiniGlass}}/>
          <ServiceLink {...{title: "VIP Events & Parties", link:"services/vip-events-parties", image: shotGlass}}/>
          <ServiceLink {...{title: "Ambassadorship & Representation", link:"services/ambassadorship-representation", image: tallGlass}}/>
          <ServiceLink {...{title: "Workplace Culture, Harassment & Safety", link:"services/workplace-culture-harassment-safety", image: safetyImage}}/>
        </div>
      </div>
      <div className="page-section flex">
        <ClientList/>
      </div>
    </div>
  </Layout>
)

export default IndexPage
